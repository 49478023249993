import React from "react"
import PropTypes from "prop-types"
import ImgBaguette from "./imgBaguette"

class PrevisuPreCoupe extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const largeurCadre = parseFloat(this.props.largeurCadre)
    const hauteurCadre = parseFloat(this.props.hauteurCadre)

    const largeurFixe = this.props.largeurFixe

    const backgroundPouBlanc = this.props.backgroundPouBlanc

    var baguetteLarge
    var baguettePetit

    if (largeurCadre > hauteurCadre) {
      baguetteLarge = largeurFixe
      baguettePetit = (hauteurCadre * largeurFixe) / largeurCadre
    } else {
      baguetteLarge = largeurFixe
      baguettePetit = (largeurCadre * largeurFixe) / hauteurCadre
    }

    var epaisseurBaguette
    var heightMarge
    var heightCarreLeft
    var widthCadreFocus
    if (this.props.component === "product") {
      epaisseurBaguette = 30
      heightMarge = 60
      heightCarreLeft = 50
      widthCadreFocus = "auto"
    } else {
      epaisseurBaguette = 10
      heightMarge = 40
      heightCarreLeft = 50
      widthCadreFocus = "250px"
    }

    const divParent = {
      height: "300px",
      backgroundColor: backgroundPouBlanc,
      paddingTop: "20px",
      width: widthCadreFocus,
      overflow: "hidden",
      marginLeft: largeurFixe === 700 ? "0px" : "10px",
    }

    const baguetteStyle = {
      position: "absolute",
      overflow: "hidden",
      height: epaisseurBaguette + "px",
      width: baguetteLarge + "px",
    }
    const baguetteStyle1 = {
      position: "absolute",
      overflow: "hidden",
      width: baguettePetit + "px",
      height: epaisseurBaguette + "px",
      marginBottom: epaisseurBaguette + "px",
    }
    const carreHideLeft = {
      position: "absolute",
      width: "50px",
      height: "50px",
      backgroundColor: backgroundPouBlanc,
      transform: "rotate(45deg)",
      marginTop: "-23px",
      marginLeft: "-23px",
    }
    const carreHideRight = {
      position: "absolute",
      width: "50px",
      height: "50px",
      backgroundColor: backgroundPouBlanc,
      transform: "rotate(-45deg)",
      marginTop: "-23px",
      marginLeft: baguetteLarge - 25 + "px",
    }
    const carreHideRight1 = {
      position: "absolute",
      width: heightCarreLeft + "px",
      height: heightCarreLeft + "px",
      backgroundColor: backgroundPouBlanc,
      transform: "rotate(-45deg)",
      marginTop: "-23px",
      marginLeft: baguettePetit - 25 + "px",
    }

    const classNamePlus = this.props.classNamePlus
    return (
      <>
        <div id="divParent" className={classNamePlus} style={divParent}>
          <div style={baguetteStyle}>
            <ImgBaguette id={this.props.idProduct} style={{ height: "100%", boxShadow: "5px 5px 6px #5e5447" }} alt={"Baguette1"} />
            <div style={carreHideLeft}></div>
            <div style={carreHideRight}></div>
          </div>

          <div style={{ height: heightMarge + "px" }}></div>

          <div style={baguetteStyle}>
            <ImgBaguette id={this.props.idProduct} style={{ height: "100%", boxShadow: "5px 5px 6px #5e5447" }} alt={"Baguette2"} />
            <div style={carreHideLeft}></div>
            <div style={carreHideRight}></div>
          </div>

          <div style={{ height: heightMarge + "px" }}></div>

          <div style={baguetteStyle1}>
            <ImgBaguette id={this.props.idProduct} style={{ height: "100%", boxShadow: "5px 5px 6px #5e5447" }} alt={"Baguette3"} />
            <div style={carreHideLeft}></div>
            <div style={carreHideRight1}></div>
          </div>

          <div style={{ height: heightMarge + "px" }}></div>

          <div style={baguetteStyle1}>
            <ImgBaguette id={this.props.idProduct} style={{ height: "100%", boxShadow: "5px 5px 6px #5e5447" }} alt={"Baguette4"} />
            <div style={carreHideLeft}></div>
            <div style={carreHideRight1}></div>
          </div>
        </div>
      </>
    )
  }
}

PrevisuPreCoupe.propTypes = {
  idProduct: PropTypes.number,
  largeurFixe: PropTypes.number,
  largeurCadre: PropTypes.number,
  hauteurCadre: PropTypes.number,
  classNamePlus: PropTypes.string,
  backgroundPouBlanc: PropTypes.string,
  component: PropTypes.string,
}

export default PrevisuPreCoupe
