import React from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { setRehausseColor } from "../actions"
import ReactTooltip from "react-tooltip"
import { getPrix } from "../services/prixBaguettes"
import ImgBaguette from "./imgBaguette"
import { couleurs_rehausse, rehausses } from "../profcadresprix/index-legacy"

export const Rehausse = props => {
  const pageProduit = useSelector(s => s.PageProduit)
  const identifiants = useSelector(s => s.identifiants)
  const dispatch = useDispatch()
  return (
    <>
      <h3 style={{ marginBottom: 0, paddingBottom: 0, marginTop: "1rem" }}>Option réhausse</h3>
      <p style={{ marginTop: 0 }}>Permet un espace libre de 20 ou 30mm entre le verre et le fond du cadre (glissée dans le cadre, non fixée)</p>
      <table className="table td-vcentered" style={{ marginTop: "0.5rem" }}>
        <thead>
          <tr>
            <th>Réhausse</th>
            <th className={identifiants.identifiants === null ? "is-hidden" : "has-text-centered "}>Prix option HT</th>
            {/* <th className="has-text-centered">Prix unitaire TTC</th> */}
            <th className="has-text-centered">
              <span className="is-hidden-mobile">Sélection</span>
            </th>
            {!["none", null].includes(pageProduit.rehausse) ? <th></th> : null}
          </tr>
        </thead>
        <tbody>
          {Object.keys(rehausses)
            .filter(k => k !== "h30mm" || ![1504, 1503, 1505, 2485, 2486].includes(props.baguette_ext.ba_id))
            .map(key => (
              <tr className={pageProduit.rehausse == key ? "serviceSelected" : ""} key={`p-${key}`} style={{ cursor: "pointer" }}>
                <td>
                  <span className="columns is-vcentered" style={{ padding: 7 }}>
                    {rehausses[key].title}
                  </span>
                </td>
                <td className={identifiants.identifiants === null ? "is-hidden" : "has-text-centered "}>
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(
                    getPrix(props.baguette_ext, { ...pageProduit, rehausse: key }, props.produits_annexes) -
                      getPrix(props.baguette_ext, { ...pageProduit, rehausse: null }, props.produits_annexes)
                  )}
                </td>

                <td className="has-text-centered">
                  {key !== "none" ? (
                    <div className="colors_rehausse">
                      {Object.values(couleurs_rehausse).map((color, key2) => (
                        <>
                          <div
                            data-tip
                            data-for={`tt-${key}-${key2}`}
                            className={`color ${pageProduit.rehausse_color == key2 && pageProduit.rehausse == key ? "selected" : ""}`}
                            key={`d-${key}-${key2}`}
                            onClick={() => dispatch(setRehausseColor(key, key2))}
                            onKeyDown={() => dispatch(setRehausseColor(key, key2))}
                            role="button"
                            tabIndex={key2}
                            style={{ background: color.color, overflow: "hidden" }}
                          >
                            {typeof color.ba_id_img !== "undefined" ? <ImgBaguette type="coin" id={color.ba_id_img} style={{ top: 0 }} /> : null}
                            <ReactTooltip id={`tt-${key}-${key2}`} place="top" key={`di-${key}-${key2}`} type="info" effect="solid">
                              <div style={{ textAlign: "center" }}>{color.title}</div>
                            </ReactTooltip>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    <input
                      type="checkbox"
                      name="selectService"
                      value={1}
                      onChange={() => dispatch(setRehausseColor("none", null))}
                      checked={pageProduit.rehausse == key}
                    />
                  )}
                </td>
                {pageProduit.rehausse == key && !["none", null].includes(pageProduit.rehausse) ? (
                  <td>{Object.values(couleurs_rehausse)[pageProduit.rehausse_color].title}</td>
                ) : null}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  )
}

Rehausse.propTypes = {
  baguette_ext: PropTypes.object,
  produits_annexes: PropTypes.array,
  pu_ht: PropTypes.number,
}

export default Rehausse
