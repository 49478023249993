import React from "react"
import PropTypes from "prop-types"
import rehausse from "./../images/rehausse.svg"

class Croquis extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { largeurFixe, tailleCote, couleurCotes } = this.props
    const paddingCotes = 2

    //Ratio entre la taille réelle des profils et la largeur désirée de l'image finale
    let ratio = (largeurFixe - tailleCote) / (this.props.product.ba_largeur + (this.props.doublo !== null ? this.props.doublo.ba_largeur : 0))

    if (ratio < 1) {
      //pour mobile
      ratio = Math.abs(ratio) * 2
    }

    if (this.props.product.ba_hauteur * ratio > 120) {
      ratio = 120 / this.props.product.ba_hauteur
    }

    //Calcul des largeurs des images rapportées au ratio
    const largeur = this.props.product.ba_largeur * ratio
    const hauteur = this.props.product.ba_hauteur * ratio
    const largeurCM = this.props.doublo !== null ? this.props.product.ba_talon + this.props.doublo.ba_largeur : this.props.product.ba_largeur
    const epaisseurCM =
      this.props.doublo !== null
        ? this.props.product.ba_hauteur - this.props.product.ba_hauteur_feuillure + this.props.doublo.ba_hauteur
        : this.props.product.ba_hauteur
    //Styles
    const styleCroquis = {
      height: hauteur + tailleCote,
      marginBottom: 5,
      marginTop: 8,
    }

    const styleImage = {
      left: tailleCote,
      top: tailleCote,
      width: largeur,
      height: hauteur,
      position: "absolute",
      marginBottom: "3.2rem",
    }

    const styleLargeurCote = {
      left: tailleCote,
      top: "0px",
      width: largeurCM * ratio,
      height: tailleCote,
      lineHeight: tailleCote + "px",
      position: "absolute",
      borderStyle: "dashed solid none solid",
      borderWidth: "1px",
      borderColor: couleurCotes,
      textAlign: "center",
      overflow: "hidden",
    }

    const styleHauteurCote = {
      top: tailleCote,
      width: tailleCote,
      height: epaisseurCM * ratio,
      lineHeight: hauteur + "px",
      position: "absolute",
      borderStyle: "solid none solid dashed",
      borderWidth: "1px",
      borderColor: couleurCotes,
      textAlign: "center",
      overflow: "hidden",
    }
    /*
        const styleFeuillureCote = {
            left: tailleCote + ancrageX,
            top: hauteur - ancrageY + tailleCote,
            width: tailleCote,
            height: ancrageY,
            lineHeight: ancrageY + 'px',
            position: 'absolute',
            borderStyle: 'solid dashed solid none',
            borderWidth: '1px',
            borderColor: couleurCotes,
            textAlign: 'center',
            overflow: 'hidden'
        }
*/
    const styleCotes = {
      display: this.props.hideText ? "none" : "block",
    }

    const styleTexteCotes = {
      padding: paddingCotes,
      backgroundColor: "white",
      fontFamily: this.props.font,
      fontSize: "10pt",
      color: couleurCotes,
    }
    const styleTexteCotesHauteur = {
      padding: paddingCotes,
      backgroundColor: "white",
      fontFamily: this.props.font,
      fontSize: "10pt",
      color: couleurCotes,
      position: "absolute",
      marginLeft: "-35px",
      transform: "rotate(-90deg)",
    }
    let styleImageDoublo
    if (this.props.doublo !== null) {
      styleImageDoublo = {
        maxWidth: this.props.doublo.ba_largeur * ratio,
        maxHeight: this.props.doublo.ba_hauteur * ratio,
        position: "absolute",
        filter: "invert(59%) sepia(14%) saturate(5%) hue-rotate(12deg) brightness(88%) contrast(87%)", // https://codepen.io/sosuke/pen/Pjoqqp
        left: tailleCote + this.props.product.ba_talon * ratio,
        top: tailleCote + (hauteur - this.props.product.ba_hauteur_feuillure * ratio),
      }
    }
    let stylerehausse
    if (this.props.rehausse !== null) {
      stylerehausse = {
        position: "absolute",
        maxWidth: 1.6 * ratio,
        maxHeight: (this.props.rehausse == 1 ? 1.6 : 2.2) * ratio,
        filter: "invert(59%) sepia(14%) saturate(5%) hue-rotate(12deg) brightness(88%) contrast(87%)", // https://codepen.io/sosuke/pen/Pjoqqp
        left: tailleCote + (this.props.product.ba_talon * largeur) / this.props.product.ba_largeur + 2,
        top: tailleCote + ((this.props.product.ba_hauteur - this.props.product.ba_hauteur_feuillure) * hauteur) / this.props.product.ba_hauteur + 15,
      }
    }
    return (
      <>
        <div className="containerImages" style={styleCroquis}>
          <img style={styleImage} src={this.props.url} alt="croquis" />
          <div style={styleCotes}>
            <div style={styleLargeurCote}>
              <span style={styleTexteCotes}>{largeurCM.toFixed(2).replace(/(\.0|0)+$/, "")} cm</span>
            </div>
            <div style={styleHauteurCote}>
              <span style={styleTexteCotesHauteur}>{epaisseurCM.toFixed(2).replace(/(\.0|0)+$/, "")} cm</span>
            </div>
            {/* <div style={styleFeuillureCote}>
                            <span style={styleTexteCotes}>{this.props.feuillure} cm</span>
                        </div> */}
          </div>
          {this.props.doublo !== null ? (
            <img
              style={styleImageDoublo}
              src={this.props.doublo.croquis_svg !== null ? this.props.doublo.croquis_svg : this.props.doublo.croquis}
              alt="croquis"
            />
          ) : null}
          {this.props.rehausse !== null ? <img src={rehausse} style={stylerehausse} alt="rehausse" /> : null}
        </div>
        {this.props.showHauteurFeuillure && !this.props.hideText ? (
          <div style={{ ...styleTexteCotes, marginTop: this.props.doublo !== null ? 70 : 0 }}>Hauteur de feuillure : {this.props.feuillure} cm</div>
        ) : null}
      </>
    )
  }
}
Croquis.default_props = {
  hideText: false,
}
Croquis.propTypes = {
  product: PropTypes.object,
  url: PropTypes.string,
  feuillure: PropTypes.number,
  doublo: PropTypes.object,
  rehausse: PropTypes.number,
  largeurFixe: PropTypes.number,
  tailleCote: PropTypes.number,
  fontSize: PropTypes.number,
  font: PropTypes.string,
  couleurCotes: PropTypes.string,
  showHauteurFeuillure: PropTypes.bool,
  hideText: PropTypes.bool,
}

export default Croquis
