import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { setDimensionsInvalid, setDimensions,setDimensionsInter} from "../actions"
import { get_service } from "../services/prixBaguettes"

class Dimensions extends React.Component {
  constructor(props) {
    super(props)
    this.hauteurInput = React.createRef()
    let dimensions_saisies = { hauteur: props.pageProduit.hauteur, largeur: props.pageProduit.largeur }
    this.state = {
      dimensions_saisies,
      quantite: 1,
      type_incorrect: "",
      maxCheck:this.checkMax(this.props.product),
    }
  }

  handleUpdate = event => {
    event.preventDefault()
    let dim = { ...this.state.dimensions_saisies, [event.target.name]: event.target.value }
    this.setState({
      dimensions_saisies: dim,
      type_incorrect: "",
    })
    this.props.dispatch(setDimensionsInter(false))
    if (
      !(
        isNaN(parseFloat(event.target.value)) ||
        parseFloat(dim.hauteur) < this.props.ba_min ||
        parseFloat(dim.largeur) < this.props.ba_min ||
        parseFloat(dim.hauteur) > this.state.checkMax ||
        parseFloat(dim.largeur) > this.state.checkMax
      )
    ) {
      let perimetre = 0
      perimetre = ((dim.hauteur * 2 + dim.largeur * 2) * (this.props.ba_chutes / 100 + 1)) / 100
      if (parseFloat(dim.hauteur) > this.props.ba_max || parseFloat(dim.largeur) > this.props.ba_max) {
        this.props.dispatch(setDimensionsInter(true))
        this.setState({
          dimensions_saisies: dim,
          type_incorrect: "dimInter"
        })
        this.props.dispatch(setDimensions(parseFloat(dim.largeur), parseFloat(dim.hauteur)))
        return false
      }
      if (this.props.stock < perimetre) {
        alert("Stock insuffisant pour ces dimensions")
        this.props.dispatch(setDimensionsInvalid())
        this.setState({ type_incorrect: "stock" })
        return false
      }
      if (this.verifPerimetre(parseFloat(dim.hauteur), parseFloat(dim.largeur))) {
        //alert("Le périmetre maximal pour cette baguette est "+ this.props.ba_perimetre_maxi +" cm")
        this.props.dispatch(setDimensionsInvalid())
        this.setState({ type_incorrect: "perimetre" })
        return false
      } else {
        this.props.dispatch(setDimensions(parseFloat(dim.largeur), parseFloat(dim.hauteur)))
      }
    } else {
      this.props.dispatch(setDimensionsInvalid())
      this.setState({ type_incorrect: "dimensions" })
    }
  }

  verifPerimetre = (hauteur, largeur) => {
    if (this.props.pageProduit.doublo !== null) {
      return hauteur > 150 || largeur > 150 || (hauteur + largeur) * 2 > 480 || (hauteur + largeur) * 2 >= this.props.ba_perimetre_maxi
    }
    return (hauteur + largeur) * 2 > 600
  }

  checkMax = (product) => {
    let services = get_service(product)
    if(services.includes(2)){
      return "150"
    }else{
      return this.props.ba_max
    }
  }

  render() {
    return (
      <>
        <div style={{ marginTop: "2rem" }} className="field is-horizontal">
          <div className="field-body">
            <div className="field sizesNotSet">
              {this.props.famille === 475 ? (
                <p>
                  Ce cadre entre 2 verres est spécialement prévu pour un montage entre 2 verres donnant un effet de transparence Il est conseillé de mettre une
                  marge transparence de 5cm de chaque côté : si votre sujet mesure <b>30x40cm</b> , il vous faut commander un cadre de dimension <b>40x50cm</b>
                </p>
              ) : (
                ""
              )}
              <div className="control">
                <span style={{ top: 5, position: "relative" }}>Je renseigne les dimensions précises de mon sujet : </span>
                <input
                  type="number"
                  name="largeur"
                  value={this.state.dimensions_saisies.largeur}
                  onChange={this.handleUpdate}
                  className="input input-number"
                  onFocus={() => {
                    this.setState({ dimensions_saisies: { ...this.state.dimensions_saisies, largeur: "" } })
                    this.props.dispatch(setDimensionsInvalid())
                  }}
                  min={this.props.ba_min}
                  max={this.checkMax(this.props.product)}
                  step=".1"
                  required
                />
                <span style={{ position: "relative", top: 5 }}>&nbsp;X&nbsp;</span>
                <input
                  id="hauteur"
                  type="number"
                  name="hauteur"
                  value={this.state.dimensions_saisies.hauteur}
                  onChange={this.handleUpdate}
                  className="input input-number"
                  onFocus={() => {
                    this.setState({ dimensions_saisies: { ...this.state.dimensions_saisies, hauteur: "" } })
                    this.props.dispatch(setDimensionsInvalid())
                  }}
                  min={this.props.ba_min}
                  max={this.checkMax(this.props.product)}
                  step=".1"
                  required
                  ref={this.hauteurInput}
                />
                <span style={{ position: "relative", top: 5 }}>
                  &nbsp;cm{" "}
                  <small>
                    (min {this.props.ba_min}cm / max {this.checkMax(this.props.product)}cm)
                  </small>
                </span>
              </div>
            </div>
          </div>
        </div>
        {(this.state.type_incorrect == "perimetre" ||this.state.type_incorrect == "dimensions") &&  this.props.pageProduit.dimensions_valid === false && this.state.dimensions_saisies.hauteur >= 10 && this.state.dimensions_saisies.largeur >= 10 ? (
          <div className="notification is-danger">
            {this.state.type_incorrect == "perimetre" ? (
              <>Le périmètre maximum est 600cm</>
            ) :(
              ""
            )}

            {this.state.type_incorrect == "dimensions" ? (
             <>Une des dimensions n&apos;est pas comprise entre {this.props.ba_min} et {this.props.ba_max}cm</>
            ) :(
             ""
            )}
          </div>
        ) : (
          ""
        )}

        {this.state.type_incorrect == "dimInter" ? (
          <>
            <div className="notification is-danger">
              Cette moulure ne vous est proposée que en service précoupé car le maximum de côté possible en coupé assemblé est de  {this.props.ba_max}cm avec un périmètre maximum du cadre de {this.props.ba_perimetre_maxi}cm.
            </div>
          </>
        ) :""}
         
      </>
    )
  }
}

Dimensions.propTypes = {
  dispatch: PropTypes.func,
  pageProduit: PropTypes.object,
  ba_max: PropTypes.number,
  ba_min: PropTypes.number,
  ba_perimetre_maxi: PropTypes.number,
  stock: PropTypes.number,
  ba_chutes: PropTypes.number,
  famille: PropTypes.number,
  product: PropTypes.object,
  services: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    pageProduit: state.PageProduit,
   
  }
}

export default connect(mapStateToProps)(Dimensions)
