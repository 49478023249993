import React from "react"
import PropTypes from "prop-types"
import ImgBaguette from "./imgBaguette"

class PrevisuLongueur extends React.Component {
    constructor(props) {
      super(props)
    }

    render() {
        const baguetteStyle = {
            height: '30px',
            marginBottom: '30px',
            boxShadow: '5px 5px 6px #cac9c9'
        }
        const baguetteStyle1 = {
            width: '50%',
            height: '30px',
            marginBottom: '30px',
            boxShadow: '5px 5px 6px #cac9c9'
        }

        const classNamePlus = this.props.classNamePlus;

        return(
            <>
                <div  className= {classNamePlus} style={{height: '300px', backgroundColor: 'white', padding: '20px'}}>
                    <ImgBaguette style={baguetteStyle} id={this.props.idProduct} alt={"Baguette1"} />
                
                    <ImgBaguette style={baguetteStyle} id={this.props.idProduct} alt={"Baguette2"} />
                
                    <ImgBaguette style={baguetteStyle1} id={this.props.idProduct} alt={"Baguette3"} />
                
                    <ImgBaguette style={baguetteStyle1} id={this.props.idProduct} alt={"Baguette4"} />          
                </div>              

            </>
        )
    }
}

PrevisuLongueur.propTypes = {
    idProduct: PropTypes.number,
    classNamePlus: PropTypes.string,
}

export default PrevisuLongueur
