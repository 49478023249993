import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby-plugin-spinner"

import AjouterPanier from "../components/ajouterPanier"
import Dimensions from "../components/dimensions"
import Calculateur from "../components/calculateur"
import Rehausse from "../components/rehausse"

import PrevisuCadreComplet from "./../components/previsuCadreComplet"
import PrevisuLongueur from "./../components/previsuLongueur"
import PrevisuPreCoupe from "./../components/previsuPreCoupe"
import Croquis from "./../components/croquis"

import { get_prix_services, services_rehausse, getPrix } from "../services/prixBaguettes"
import { get_product_to_add } from "../services/panier"
import SEO from "./../components/seo"
import Img from "gatsby-image"
import { resetPageProduct, setBaguetteDoublo, mjsService } from "../actions/index"

import slugify from "slugify"
import ImgBaguette from "../components/imgBaguette"

import ChoixDoublo from "../components/choixDoublo"
import Atrium from "../components/atrium"
import { setRehausseColor } from "../actions"

import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import { rehausses, woodColors } from "../profcadresprix/index-legacy"
import ReactTooltip from "react-tooltip"

export const ProductPage = props => {
  const dispatch = useDispatch()
  const pageProduit = useSelector(s => s.PageProduit)
  const identifiants = useSelector(s => s.identifiants)
  useEffect(() => {
    const hauteur = pageProduit.hauteur
    const largeur = pageProduit.largeur
    dispatch(resetPageProduct(props.pageContext.product.ba_id, hauteur, largeur))
    // Si on est sur un doublo :
    if (props.pageContext.doublo.length >= 1) {
      dispatch(
        setBaguetteDoublo(
          props.pageContext.doublo.find(e =>
            props.pageContext.product.doublo_defaut
              .split(",")
              .map(f => parseInt(f))
              .includes(e.node.ba_id)
          ).node
        )
      )
    }
    // Caisse américaine support peinture et rigide / marie louis en coupé assemblé ticket gitlab #162 / #171
    if ([10, 3, 13, 21].includes(props.pageContext.product.cat_id)) {
      dispatch(mjsService("coupe_assemble"))
    }
  }, [])
  const product = props.pageContext.product
  const [tmpRehausseColor, setTmpRehausseColor] = useState(null)
  const [tmpRehausse, setTmpRehausse] = useState(null)
  const [zoomed, setZoomed] = useState(false)
  const [stock, setStock] = useState(1000)
  const [baContinue, setBaContinue] = useState(product.ba_continue)

  const data = useStaticQuery(graphql`
    query produit {
      attache: file(relativePath: { eq: "cadre_carre.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      astral_arriere: file(relativePath: { eq: "astral-arriere.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      astral_face: file(relativePath: { eq: "astral-face.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      astral_zoom: file(relativePath: { eq: "astral-zoom-accroche.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      vitrine: file(relativePath: { eq: "vitrine.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      vitrine_montage: file(relativePath: { eq: "vitrine-montage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      cristal_face: file(relativePath: { eq: "cristal-face.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      cristal_arriere: file(relativePath: { eq: "cristal-arriere.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  let timer = null

  const RedirectRehausseSelected = (key, color, service) => {
    dispatch(setRehausseColor(key, color))
    dispatch(mjsService(service))
  }
  useEffect(() => {
    setTmpRehausseColor(pageProduit.rehausse_color)
    setTmpRehausse(pageProduit.rehausse)
    /*  ResetRehausse() */
  }, [pageProduit.rehausse_color !== null])

  useEffect(() => {
    GetBackendStock(product.ba_id, "baguette")
    timer = setInterval(() => GetBackendStock(product.ba_id, "baguette"), 60000)
    return function cleanup() {
      clearInterval(timer)
    }
  })

  const GetBackendStock = (id_p, category_p) => {
    if (typeof window !== "undefined") {
      var url = process.env.GATSBY_API_URL + "/index.php?controller=authentification"
      fetch(url, {
        method: "POST",
        body: JSON.stringify({
          action: "getBStock",
          id: id_p,
          category: category_p,
          from: "product",
        }),
      })
        .then(response => response.json())
        .then(result => {
          setBaContinue(parseInt(result.ba_continue))
          setStock(parseFloat(result.stock))
        })
    }
  }

  const renderPrevisus = (baguette_doublo, maxWidthPrevisu, size) => {
    return props.pageContext.category.cat_id === 17 ? (
      <Img fluid={data.astral_face.childImageSharp.fluid} alt="Cadre astral" />
    ) : pageProduit.service === "longueur" ? (
      <PrevisuLongueur idProduct={product.ba_id} classNamePlus={"cadreFocus"} />
    ) : pageProduit.service === "precoupe" ? (
      <PrevisuPreCoupe
        idProduct={product.ba_id}
        largeurFixe={maxWidthPrevisu < size - 40 ? maxWidthPrevisu : size - 40}
        largeurCadre={pageProduit.hauteur}
        hauteurCadre={pageProduit.largeur}
        classNamePlus={"cadreFocus"}
        component={"product"}
        backgroundPouBlanc={product.cog_id === 3 ? "#9F8F7E" : "white"}
      />
    ) : (
      <PrevisuCadreComplet
        idProduct={product.ba_id}
        hauteur={product.ba_largeur}
        tailleMax={product.ba_maxi}
        largeurFixe={maxWidthPrevisu < size ? maxWidthPrevisu : size}
        hauteurFixe={maxWidthPrevisu < size ? maxWidthPrevisu : size}
        largeurCadre={pageProduit.largeur}
        hauteurCadre={pageProduit.hauteur}
        couleurPassePartout={pageProduit.cartonBarriere ? "#FBEAD3" : "#cdae83"}
        service={pageProduit.service}
        doublo={baguette_doublo}
        classNamePlus={`cadreFocus`}
      />
    )
  }

  let services = get_prix_services(product, props.pageContext.pa, parseInt(pageProduit.hauteur), parseInt(pageProduit.largeur), [])
  const StockMini = (product.ba_mini * 4 * (product.ba_chutes / 100 + 1)) / 100
  let stateService = get_prix_services(product, props.pageContext.pa, parseInt(pageProduit.hauteur), parseInt(pageProduit.largeur), parseInt(1), [])
  let produit_service_selectionne = get_product_to_add(
    pageProduit.service,
    stateService[pageProduit.service],
    product,
    1,
    pageProduit.largeur,
    pageProduit.hauteur,
    pageProduit.kitMontage
  )

  const styleImageAttache = {
    width: "11rem",
    height: "10rem",
    margin: "5px 10%",
  }

  const styleImageMontage = {
    width: "325px",
    marginBottom: "15px",
  }

  const familleSansAttaches = [476, 510, 511, 540, 475, 672, 675, 707]

  let d = new Date()

  const width_croquis = typeof window !== "undefined" ? ((window.innerWidth - 64 - 24) / 12) * 2 : 1000
  const maxWidthPrevisu = typeof window !== "undefined" ? window.innerWidth - 64 - 24 : 1000 // Width du fluid - margin du cadre
  let baguette_doublo = pageProduit.doublo
  return (
    <>
      <Layout banner={false} location={props.location}>
        <SEO
          title={` ${product.cat_libelle} - ${product.co_libelle} - ${product.fa_libelle}`}
          description={product.ba_descriptif}
          jsonLd={[
            {
              "@context": "http://schema.org",
              "@type": "Product",
              identifier: product.bas_reference,
              description: product.ba_descriptif,
              name: product.fa_libelle,
              sku: product.ba_id,
              url: props.location.href,
              mpn: "B-" + product.ba_id,
              brand: {
                "@type": "Brand",
                name: "Les Professionnels du Cadre",
              },
              offers: {
                "@type": "Offer",
                availability: "http://schema.org/InStock",
                price: product.type !== "baguette" ? parseFloat(product.prix_produit) : getPrix(props.pageContext.product, pageProduit, props.pageContext.pa),
                url: props.location.href,
                priceCurrency: "EUR",
                priceValidUntil: d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2),
              },
              itemCondition: "https://schema.org/NewCondition",
              image: product.ba_photo_longueur,
              category: {
                "@type": "PhysicalActivityCategory",
                name: product.cat_libelle,
              },
            },
          ]}
        />
        {stock < StockMini && baContinue == 1 ? <h2 style={{ marginTop: "5rem", color: "#990000" }}>Attention stock insuffisant ! </h2> : ""}
        <h2 style={{ marginTop: "6rem", marginBottom: "0.5rem" }}>
          {props.pageContext.doublo.length >= 1 ? (
            <Link to={`/categorie/${slugify(props.pageContext.category.cat_libelle.replace('(', '').replace(')', '').replace('...', ''))}`}>{props.pageContext.category.cat_libelle} </Link>
          ) : (
            <Link to={`/categorie/${slugify(product.cat_libelle.replace('(', '').replace(')', '').replace('...', ''))}`}>{product.cat_libelle} </Link>
          )}
          / <strong>{product.fa_libelle}</strong>
        </h2>

        <div className="columns is-multiline">
          <div className="column is-6-widescreen">
            <div className="columns h100">
              <div className="column is-8">
                <p>
                  Réf. <strong>{product.bas_reference.toUpperCase()}</strong>
                </p>
                <p>
                  {product.ba_descriptif}{" "}
                  {baguette_doublo !== null
                    ? " + " +
                      baguette_doublo.ba_descriptif?.toLowerCase() +
                      " Largeur totale : " +
                      (baguette_doublo.ba_largeur + product.ba_largeur - (product.ba_largeur - product.ba_talon)).toFixed(2).replace(/(\.0|0)+$/, "") +
                      "cm"
                    : null}
                  {[510, 511].includes(product.fa_id) &&
                    pageProduit.service == "precoupe" &&
                    "le sujet qui sera glissé dans le cadre sera en retrait de 9mm de la face du cadre assurant un effet très contemporain) mise en place du sujet rapide et simple: assemblage du cadre par double équerre à vis (livré avec le système d'accrochage qui se glisse dans le profilé) encadrement non assemblé, livré avec son sachet d'accessoires"}
                </p>
                <div className={`previsu`}>
                  <div
                    onClick={() => setZoomed(true)}
                    onKeyPress={() => {}}
                    style={{ marginLeft: "0rem" }}
                    tabIndex={0}
                    role="button"
                    className={`${product.cog_id === 3 || (baguette_doublo !== null && baguette_doublo.cog_id === 3) ? "backgroundPouBlanc" : ""} `}
                  >
                    {renderPrevisus(baguette_doublo, maxWidthPrevisu, 400)}
                  </div>
                  {props.pageContext.category.cat_id === 17 ? (
                    <span style={{ textAlign: "center", display: "block", margin: "0 auto", paddingTop: "20px" }}>Vendu sans le sujet</span>
                  ) : (
                    ""
                  )}
                  {product.cat_id == 1 ? (
                    <div className="columns">
                      <div className="column">
                        <Zoom zoomMargin={150} zoomZindex={99} wrapStyle={{ display: "block" }}>
                          <Img fluid={data.vitrine.childImageSharp.fluid} alt="Cadre vitrine" style={{ maxWidth: 250, margin: "2rem auto" }} />
                        </Zoom>
                      </div>
                      <div className="column">
                        {pageProduit.rehausse !== null && (
                          <Zoom zoomMargin={150} zoomZindex={99} wrapStyle={{ display: "block" }}>
                            <Img fluid={data.vitrine_montage.childImageSharp.fluid} alt="Cadre vitrine" style={{ maxWidth: 180, margin: "2rem auto" }} />
                          </Zoom>
                        )}
                      </div>
                    </div>
                  ) : product.cat_id == 16 ? (
                    <div className="columns">
                      <div className="column">
                        <Zoom zoomMargin={150} zoomZindex={99} wrapStyle={{ display: "block" }}>
                          <Img fluid={data.cristal_face.childImageSharp.fluid} alt="Cadre entre 2 verres" style={{ maxWidth: 250, margin: "2rem auto" }} />
                        </Zoom>
                      </div>
                      <div className="column">
                        <Zoom zoomMargin={150} zoomZindex={99} wrapStyle={{ display: "block" }}>
                          <Img fluid={data.cristal_arriere.childImageSharp.fluid} alt="Cadre entre 2 verres" style={{ maxWidth: 250, margin: "2rem auto" }} />
                        </Zoom>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="is-flex is-justify-content-center">
                    <a href={`/${product.fa_id}.pdf`} rel="noreferrer" target="_blank" className="button mt-5" download style={{ height: "auto" }}>
                      <FontAwesomeIcon icon={faDownload} className="mr-2" />
                      Télécharger
                      <br />
                      la fiche produit
                    </a>
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div className="sticky">
                  <div className="croquis" style={{ marginTop: 20, marginBottom: "2rem" }}>
                    <Croquis
                      url={
                        pageProduit.rehausse == 12
                          ? rehausses[pageProduit.rehausse].croquis
                          : product.croquis_svg !== null
                          ? product.croquis_svg
                          : product.croquis
                      }
                      feuillure={baguette_doublo === null ? product.ba_hauteur_feuillure : baguette_doublo.ba_hauteur_feuillure}
                      product={product}
                      largeurFixe={width_croquis - 50}
                      tailleCote={50}
                      couleurImage={"black"}
                      couleurCotes={"#a2388d"}
                      font={"Georgia"}
                      fontSize={12}
                      rehausse={pageProduit.rehausse}
                      doublo={baguette_doublo}
                      showHauteurFeuillure={![18, 3].includes(props.pageContext.category.cat_id)}
                    />
                  </div>

                  {props.pageContext.category.cat_id === 17 ? (
                    <Zoom zoomMargin={80}>
                      <Img fluid={data.astral_zoom.childImageSharp.fluid} alt="photo des attaches" style={{ width: "150px", margin: "0 auto" }} />
                    </Zoom>
                  ) : (
                    ""
                  )}

                  <div>{product.photo_montage !== null ? <img style={styleImageMontage} src={product.photo_montage} alt="photo_montage" /> : ""}</div>
                  <div>
                    {pageProduit.service !== "precoupe" &&
                    pageProduit.service !== "coupe_assemble" &&
                    !familleSansAttaches.includes(product.fa_id) &&
                    props.pageContext.category.cat_id !== 17 ? (
                      <Zoom zoomMargin={200} wrapStyle={{ width: "14rem" }}>
                        <Img fluid={data.attache.childImageSharp.fluid} alt="photo des attaches" style={styleImageAttache} />
                      </Zoom>
                    ) : (
                      ""
                    )}
                    {props.pageContext.category.cat_id == 17 ? (
                      <Zoom zoomMargin={150}>
                        <Img
                          fluid={data.astral_arriere.childImageSharp.fluid}
                          alt="photo arrière cadre astral"
                          style={{ width: "200px", margin: "10px auto" }}
                        />
                      </Zoom>
                    ) : (
                      ""
                    )}
                  </div>

                  <h3 style={{ marginTop: "1.5rem" }}>Couleurs disponibles</h3>

                  <div className="columns is-mobile" style={{ padding: "1rem", flexFlow: "row wrap" }}>
                    {props.pageContext.otherColors.map(({ node }, index) =>
                      (node.baguettes_doublo !== null && [18, 19, 20].includes(props.pageContext.category.cat_id)) ||
                      ![18, 19, 20].includes(props.pageContext.category.cat_id) ? (
                        <Link
                          className={`column ${node.ba_continue !== 2 ? "" : "rupture rupture_mini"} is-4 ${
                            product.ba_id == node.ba_id ? "couleur_selected" : ""
                          }`}
                          key={index}
                          style={{ height: "70px", cursor: "pointer" }}
                          data-tip={
                            typeof woodColors.find(e => e.id.includes(node.ba_id)) === "undefined"
                              ? node.co_libelle
                              : woodColors.find(e => e.id.includes(node.ba_id)).title
                          }
                          to={`/cadres/${
                            props.pageContext.doublo.length >= 1
                              ? slugify(props.pageContext.category.cat_libelle.replace('(', '').replace(')', '').replace('...', '')) 
                              : slugify(node.cat_libelle.replace('(', '').replace(')', '').replace('...', ''))
                          }-${slugify(node.fa_libelle)}-${slugify(node.co_libelle)}-${node.ba_id}`}
                        >
                          <ImgBaguette className="couleurShadow" type="coin" style={{ height: "100%", width: "100%" }} id={node.ba_id} alt={"Baguette1"} />
                        </Link>
                      ) : null
                    )}
                    <ReactTooltip effect="solid" />
                  </div>
                </div>
              </div>

              <div className="modal" style={{ display: zoomed ? "block" : "none" }}>
                <div
                  className="modal-background"
                  onClick={() => setZoomed(false)}
                  onKeyPress={() => {}}
                  tabIndex={0}
                  role="button"
                  style={{ backgroundColor: "rgba(168, 168, 168, 0.86)" }}
                ></div>
                <div className="modal-content has-text-centered" style={{ marginTop: "7rem", width: maxWidthPrevisu < 750 ? maxWidthPrevisu : 750 }}>
                  <div className="image">{renderPrevisus(baguette_doublo, maxWidthPrevisu, 750)}</div>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={() => setZoomed(false)}></button>
              </div>
            </div>
          </div>

          <div className="column is-mobile is-tablet is-6-widescreen ">
            <Dimensions
              ba_max={product.ba_maxi}
              ba_min={[18, 20].includes(props.pageContext.category.cat_id) ? 25 : product.ba_mini}
              ba_perimetre_maxi={product.ba_perimetre_maxi}
              stock={stock}
              ba_chutes={product.ba_chutes}
              famille={product.fa_id}
              services={services}
              product={{
                ...product,
                cat_id: typeof props.pageContext.category !== "undefined" ? props.pageContext.category.cat_id : product.cat_id,
              }}
            />
            {pageProduit.largeur == 40 && pageProduit.hauteur == 50 ? (
              <p style={{ fontSize: "1.1rem" }}>
                <strong>
                  La grille tarifaire ci-dessous correspond à un cadre {pageProduit.doublo !== null ? " doublo" : null} de dimension {pageProduit.largeur} x{" "}
                  {pageProduit.hauteur} cm
                </strong>
                <small>
                  <i> à titre d&apos;exemple</i>
                </small>
              </p>
            ) : (
              ""
            )}

            <Calculateur
              services={services}
              product={{
                ...product,
                cat_id: typeof props.pageContext.category !== "undefined" ? props.pageContext.category.cat_id : product.cat_id,
              }}
              produitAnnexe={props.pageContext.pa}
            />
            {pageProduit.service !== null ? (
              <>
                {props.pageContext.doublo.length >= 1 ? (
                  <>
                    <p style={{ fontSize: "1.1rem", marginTop: 10 }}>
                      <strong>
                        Je selectionne la couleur intérieure de mon {props.pageContext?.category?.cat_libelle?.toLowerCase()?.replace("cadres", "cadre")} :{" "}
                      </strong>
                    </p>
                    {props.pageContext.doublo.some(e => e.node.fa_libelle.includes("ATRIUM")) ? (
                      <Atrium
                        baguettes={props.pageContext.doublo}
                        baguette_ext={product}
                        service={pageProduit.service}
                        produits_annexes={props.pageContext.pa}
                      />
                    ) : (
                      <ChoixDoublo
                        baguettes={props.pageContext.doublo}
                        baguette_ext={product}
                        service={pageProduit.service}
                        produits_annexes={props.pageContext.pa}
                        enableCategs={props.pageContext.category.cat_id == 20}
                      />
                    )}
                  </>
                ) : product.cat_id == 1 ? (
                  services_rehausse.includes(pageProduit.service) ? (
                    <Rehausse baguette_ext={props.pageContext.product} produits_annexes={props.pageContext.pa} />
                  ) : (
                    <div
                      className="tag is-info is-light is-medium"
                      style={{ marginTop: 20, marginBottom: 20, whiteSpace: "break-spaces", height: "auto", display: "inline-block" }}
                    >
                      L’option réhausse ne peut vous être proposée avec une protection anti-reflet pour des raisons esthétiques.<br></br>
                      Vous pouvez sélectionner cette option avec une protection
                      <span
                        role="button"
                        onKeyPress={() => {}}
                        tabIndex={0}
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => RedirectRehausseSelected(tmpRehausse, tmpRehausseColor, "plexi_normal")}
                      >
                        {" "}
                        plexi normal
                      </span>{" "}
                      ou{" "}
                      <span
                        role="button"
                        onKeyPress={() => {}}
                        tabIndex={0}
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => RedirectRehausseSelected(tmpRehausse, tmpRehausseColor, "verre_normal")}
                      >
                        verre normal
                      </span>
                    </div>
                  )
                ) : null}

                {identifiants.identifiants !== null ? (
                  <>
                    <div className="has-text-right">
                      {product.ba_continue == 1 ? (
                        <AjouterPanier
                          product_selected={produit_service_selectionne}
                          hauteur={pageProduit.hauteur}
                          largeur={pageProduit.largeur}
                          produits_annexes={props.pageContext.pa}
                          baguette={props.pageContext.product}
                          baguettes_doublo={props.pageContext.doublo}
                          disabled={!pageProduit.dimensions_valid ? "true" : ""}
                          stock={stock}
                        />
                      ) : (
                        <div style={{ textAlign: "right" }}>
                          <div className="tag is-danger" style={{ fontSize: "1.1em" }}>
                            Baguette en rupture, en cours de réapprovisionnement
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <Link
                      to="/acces"
                      className="has-text-right"
                      style={{
                        position: "absolute",
                        right: "2rem",
                      }}
                    >
                      <div className="button is-primary has-text-right">Demandez l&apos;accès au site pour obtenir les prix</div>
                    </Link>
                  </>
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

ProductPage.propTypes = {
  pageContext: PropTypes.object,
  id_familly: PropTypes.string,
  url: PropTypes.string,
  location: PropTypes.object,
}

export default ProductPage
