import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import ImgBaguette from "./imgBaguette"
import { setBaguetteDoublo } from "../actions"
import { tva, getPrix,get_service_init } from "../services/prixBaguettes"
import { useSelector, useDispatch } from "react-redux"

export const ChoixDoublo = props => {
  let categs = [
    {key:0, title: "Satiné", families: [1] },
    {key:1, title: "Laqué", families: [137] },
    {key:2, title: "Intemporel or et argent", families: [429] },
  ]
  const pageProduit = useSelector(s => s.PageProduit)
  const identifiants = useSelector(s => s.identifiants)
  const [categorie, setCategorie] = useState(0)
  useEffect(() => {
    if (pageProduit.doublo !== null) {
      setCategorie(categs.findIndex(e => e.families.includes(pageProduit.doublo.fa_id)))
    }
  }, [])

  const dispatch = useDispatch()

  let options = []
  props.baguettes
    .filter(e => !props.enableCategs || (props.enableCategs && categs[categorie].families.includes(e.node.fa_id)))
    .forEach(({ node }) => {
      let price = getPrix(props.baguette_ext, { ...pageProduit, doublo: node, ...get_service_init()[pageProduit.service].pageProduit }, props.produits_annexes)
      options.push({
        title: null,
        pu_ht: price,
        pu_ttc: price * tva,
        ba_id: node.ba_id,
        node,
      })
    })

  return (
    <>
      <nav className="panel panelDoublo">
        {props.enableCategs ? (
          <>
            <p className="choixTypeFinition">
              <b>Type de finition :</b>
              {categs.filter(c=>props.baguettes.filter(e=>c.families.includes(e.node.fa_id)).length >= 1).map((categ, index) => (
                <span
                  role="button"
                  key={index}
                  onKeyPress={() => {}}
                  tabIndex={-2}
                  onClick={e => {
                    e.preventDefault()
                    setCategorie(categ.key)
                    return false
                  }}
                  className={`disableOutline ${categorie == categ.key ? "is-active" : null}`}
                >
                  {categ.title}
                </span>
              ))}
            </p>
          </>
        ) : null}
        <table className="table td-vcentered" style={{ marginTop: "0.5rem" }}>
          <thead>
            <tr>
              <th>Baguette intérieure {props.enableCategs ? "couleur " + categs[categorie]?.title?.toLowerCase() : null}</th>
              <th className={identifiants.identifiants === null ? "is-hidden" : "has-text-centered "}>Prix unitaire HT</th>
              <th className="has-text-centered">
                <span className="is-hidden-mobile">Sélection</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {options.map((node, key) => (
              <tr
                className={pageProduit.doublo !== null && pageProduit.doublo.ba_id == node.ba_id ? "serviceSelected" : ""}
                key={`p-${key}`}
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(setBaguetteDoublo(node.node))}
              >
                <td>
                  <span className="columns is-vcentered" style={{ padding: 7 }}>
                    {node.ba_id !== null ? <ImgBaguette style={{ height: "30px", width: "100px" }} alt={"Baguette1"} id={node.ba_id} /> : node.title}
                  </span>
                </td>
                <td className={identifiants.identifiants === null ? "is-hidden" : "has-text-centered "}>
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(node.pu_ht)}
                </td>

                <td className="has-text-centered">
                  <input
                    type="radio"
                    name="selectDoublo"
                    value={1}
                    onChange={() => {}}
                    checked={pageProduit.doublo !== null && pageProduit.doublo.ba_id == node.ba_id}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </nav>
    </>
  )
}

ChoixDoublo.defaultProps = {
  enableCategs: false,
}
ChoixDoublo.propTypes = {
  produits_annexes: PropTypes.array,
  baguettes: PropTypes.array,
  baguette_ext: PropTypes.object,
  enableCategs: PropTypes.bool,
}

export default ChoixDoublo
