import React from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import ImgBaguette from "./imgBaguette"
import { setBaguetteDoublo } from "../actions"
import { tva, getPrix } from "../services/prixBaguettes"

export const Atrium = props => {
  const dispatch = useDispatch()
  const pageProduit = useSelector(s => s.PageProduit)
  const renderColors = familly => {
    let options = []
    props.baguettes.forEach(({ node }) => {
      let price = getPrix(props.baguette_ext, { ...pageProduit, doublo: node }, props.produits_annexes)
      options.push({
        pu_ht: price,
        pu_ttc: price * tva,
        ba_id: node.ba_id,
        node,
      })
    })
    return (
      <>
        {options
          .filter(e => e.node.fa_id == familly)
          .sort((a, b) => a.node.co_order - b.node.co_order)
          .map((node, key) => (
            <div
              role="button"
              onKeyPress={() => {}}
              className="disableOutline"
              tabIndex="-2"
              key={`p-${key}`}
              style={{ display: "inline-block" }}
              onClick={() => dispatch(setBaguetteDoublo(node.node))}
            >
              <ImgBaguette
                style={{
                  height: "30px",
                  margin: pageProduit.doublo !== null && pageProduit.doublo.ba_id == node.ba_id ? "6px 5px" : "6px 5px",
                  width: "30px",
                  boxShadow: "0 0 5px #888",
                  outline: ` ${pageProduit.doublo !== null && pageProduit.doublo.ba_id == node.ba_id ? "3px solid #a2388d" : "0"}`,
                }}
                alt={"Baguette1"}
                id={node.ba_id}
              />
            </div>
          ))}
      </>
    )
  }

  const renderText = familly => {
    return pageProduit.doublo !== null && pageProduit.doublo.fa_id === familly ? (
      <>
        <div style={{ color: "#777", marginTop: 0, fontSize: "0.9em" }}>Couleur sélectionnée : {pageProduit?.doublo?.co_libelle?.toLowerCase()}</div>
      </>
    ) : null
  }

  return (
    <>
      <table className="table td-vcentered" style={{ marginTop: "0.5rem" }}>
        <tbody>
          <tr>
            <td>
              <b style={{ fontSize: "1.3em" }}>Caisse américaine ATRIUM</b>
              <br /> spécialement conçu pour les châssis d’une épaisseur jusqu’à 2.5cm
              {renderText(7)}
            </td>
            <td>{renderColors(7)}</td>
          </tr>
          {props.baguettes.filter(e => e.node.fa_id == 520).length >= 1 ? (
            <tr>
              <td>
                <b style={{ fontSize: "1.3em" }}>Caisse américaine XL ATRIUM</b>
                <br /> spécialement conçu pour les châssis 3D d’une épaisseur de 3 à 4cm
                {renderText(520)}
              </td>
              <td>{renderColors(520)}</td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  )
}

Atrium.propTypes = {
  produits_annexes: PropTypes.array,
  baguettes: PropTypes.array,
  baguette_ext: PropTypes.object,
}

export default Atrium
