import React from "react"
import PropTypes from "prop-types"
import ImgBaguette from "./imgBaguette"

class PrevisuCadreComplet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      loading2: true,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idProduct !== this.props.idProduct) {
      this.setState({ loading: true })
    }
    if(prevProps.doublo !== this.props.doublo) {
      this.setState({ loading2: true })
    }
  }

  render() {
    const largeurPx = parseFloat(this.props.largeurFixe)
    const hauteurPx = parseFloat(this.props.hauteurFixe)
    const baguetteHauteurCm = parseFloat(this.props.hauteur)

    const largeurCadre = parseFloat(this.props.largeurCadre)
    const hauteurCadre = parseFloat(this.props.hauteurCadre)

    let fullWidthCm = largeurCadre + 2 * baguetteHauteurCm
    let fullHeightCm = hauteurCadre + 2 * baguetteHauteurCm
    let baguetteHauteurPx
    let cadreLargeurPx
    let cadreHauteurPx

    if (fullWidthCm > fullHeightCm) {
      baguetteHauteurPx = (baguetteHauteurCm * largeurPx) / fullWidthCm

      cadreLargeurPx = largeurPx
      cadreHauteurPx = (fullHeightCm * largeurPx) / fullWidthCm
    } else {
      baguetteHauteurPx = (baguetteHauteurCm * hauteurPx) / fullHeightCm

      cadreLargeurPx = (fullWidthCm * hauteurPx) / fullHeightCm
      cadreHauteurPx = hauteurPx
    }
    //Div carrés pour les baguettes des côtés gauche et droite
    const largeurDivCarre = cadreHauteurPx / Math.sqrt(2)

    //Largeur des petits carrés permettant de dessiner les traits dans les coins
    const largeurCarres = Math.sqrt(2 * (baguetteHauteurPx * baguetteHauteurPx))

    const styleCadre = {
      position: "relative",
      width: cadreLargeurPx,
      height: cadreHauteurPx,
      overflow: "hidden",
      backgroundColor:
        this.props.service === "coupe_assemble" ||
        this.props.service === "double_verre_normal" ||
        this.props.service === "double_verre_invisible"
          ? "transparent"
          : this.props.couleurPassePartout,
    }

    // DIV IMAGES :
    const styleDivHaut = {
      position: "absolute",
      width: cadreLargeurPx,
      height: baguetteHauteurPx,
      zIndex:2
    }

    const styleDivDroite = {
      position: "absolute",
      width: largeurDivCarre,
      height: largeurDivCarre,
      left: cadreLargeurPx - largeurDivCarre / 2 + 1,
      top: cadreHauteurPx / 2 - largeurDivCarre / 2,
      overflow: "hidden",
      transform: "rotate(45deg)",
      zIndex:2
    }

    const styleDivGauche = {
      position: "absolute",
      width: largeurDivCarre,
      height: largeurDivCarre,
      left: -(largeurDivCarre / 2).toFixed(0) - 1,
      top: cadreHauteurPx / 2 - largeurDivCarre / 2,
      overflow: "hidden",
      transform: "rotate(225deg)",
      zIndex:2
    }

    const styleDivBas = {
      position: "absolute",
      width: cadreLargeurPx,
      height: baguetteHauteurPx,
      top: cadreHauteurPx - baguetteHauteurPx + "px",
      transform: "rotate(180deg)",
      zIndex:2
    }

    // IMAGES :
    const styleImageHaut = {
      position: "absolute",
      width: largeurPx,
      height: baguetteHauteurPx + "px",
      boxShadow: this.props.doublo !== null ? '' : "1px 1px 5px rgb(85, 85, 85, 0.5)",
    }

    const styleImageBas = {
      position: "absolute",
      width: largeurPx,
      height: baguetteHauteurPx + "px",
    }
    const styleImageDroite = {
      position: "absolute",
      width: largeurPx,
      top: "0px",
      transformOrigin: "top left",
      transform: "rotate(45deg)",
      height: baguetteHauteurPx + "px",
    }

    const styleImageGauche = {
      position: "absolute",
      top: "0px",
      width: largeurPx,
      transformOrigin: "top left",
      transform: "rotate(45deg)",
      height: baguetteHauteurPx + "px",
      boxShadow: this.props.doublo !== null ? '' : "1px 1px 5px rgb(85, 85, 85, 0.5)",
    }

    let baguetteHauteurPx2,styleDivDroite2, styleDivHaut2, styleDivGauche2, styleDivBas2, styleImageHaut2, styleImageBas2, styleImageDroite2, styleImageGauche2
    if (this.props.doublo !== null) {
      if (fullWidthCm > fullHeightCm) {
        baguetteHauteurPx2 =
          (this.props.doublo.ba_largeur * largeurPx) / fullWidthCm
      } else {
        baguetteHauteurPx2 =
          (this.props.doublo.ba_largeur * hauteurPx) / fullHeightCm
      }
      const recouvrement = 3;
      let cadreLargeurPx2 = cadreLargeurPx - 2 * (baguetteHauteurPx -recouvrement)
      let cadreHauteurPx2 = cadreHauteurPx - 2 * (baguetteHauteurPx -recouvrement)
      //Conteneur baguette haut
      styleDivHaut2 = {
        width: cadreLargeurPx2,
        height: baguetteHauteurPx2,
        top: baguetteHauteurPx-recouvrement,
        left: baguetteHauteurPx-recouvrement,
        position: "absolute",
      }

      //Conteneur baguette droite
      styleDivDroite2 = {
        position: "absolute",
        width: baguetteHauteurPx2,
        height: cadreHauteurPx2,
        top: baguetteHauteurPx-recouvrement,
        right: baguetteHauteurPx-recouvrement,
        clipPath:
          "polygon(" +
          baguetteHauteurPx2 +
          "px " +
          baguetteHauteurPx2 +
          "px, 0 0, 0 " +
          cadreHauteurPx2 +
          "px, " +
          baguetteHauteurPx2 +
          "px " +
          (cadreHauteurPx2 - baguetteHauteurPx2) +
          "px)",
        transform: "rotate(180deg)",
      }

      //Conteneur baguette gauche
      styleDivGauche2 = {
        position: "absolute",
        left: baguetteHauteurPx-recouvrement,
        top: baguetteHauteurPx-recouvrement,
        width: baguetteHauteurPx2,
        height: cadreHauteurPx2,
        clipPath:
          "polygon(" +
          baguetteHauteurPx2 +
          "px " +
          baguetteHauteurPx2 +
          "px, 0 0, 0 " +
          cadreHauteurPx2 +
          "px, " +
          baguetteHauteurPx2 +
          "px " +
          (cadreHauteurPx2 - baguetteHauteurPx2) +
          "px)",
      }

      //Conteneur baguette bas
      styleDivBas2 = {
        position: "absolute",
        left: baguetteHauteurPx-recouvrement,
        bottom: baguetteHauteurPx-recouvrement,
        width: cadreLargeurPx2,
        height: baguetteHauteurPx2,
        transform: "rotate(180deg)",
      }

      //Baguette haut
      styleImageHaut2 = {
        position: "absolute",
        width: cadreLargeurPx2,
        height: baguetteHauteurPx2,
      }

      //Baguette bas
      styleImageBas2 = {
        position: "absolute",
        width: cadreLargeurPx2,
        height: baguetteHauteurPx2,
      }

      //Baguette droite
      styleImageDroite2 = {
        position: "absolute",
        width: cadreHauteurPx2,
        height: baguetteHauteurPx2,
        transform: "rotate(-90deg)",
        transformOrigin: "center",
        top: cadreHauteurPx2 / 2 - baguetteHauteurPx2 / 2,
        left: 0 - cadreHauteurPx2 / 2 + baguetteHauteurPx2 / 2,
      }

      //Baguette gauche
      styleImageGauche2 = {
        position: "relative",
        width: cadreHauteurPx2,
        height: baguetteHauteurPx2,
        transform: "rotate(-90deg)",
        transformOrigin: "center",
        top: cadreHauteurPx2 / 2 - baguetteHauteurPx2 / 2,
        left: 0 - cadreHauteurPx2 / 2 + baguetteHauteurPx2 / 2,
      }
    }
    // CARRES :
    const styleCarreHautGauche = {
      position: "absolute",
      width: largeurCarres,
      height: largeurCarres,
      top: "0px",
      borderStyle: "solid none none none",
      borderWidth: "1px",
      borderColor: "#939191",
      opacity: "0.2",
      transformOrigin: "top left",
      transform: "rotate(45deg)",
    }

    const styleCarreHautDroite = {
      position: "absolute",
      width: largeurCarres,
      height: largeurCarres,
      top: "0px",
      left: cadreLargeurPx + "px",
      borderStyle: "solid none none none",
      borderWidth: "1px",
      borderColor: "#939191",
      opacity: "0.2",
      transformOrigin: "top left",
      transform: "rotate(135deg)",
    }

    const styleCarreBasGauche = {
      position: "absolute",
      width: largeurCarres,
      height: largeurCarres,
      top: cadreHauteurPx + "px",
      borderStyle: "solid none none none",
      borderWidth: "1px",
      borderColor: "#939191",
      opacity: "0.2",
      transformOrigin: "top left",
      transform: "rotate(315deg)",
    }

    const styleCarreBasDroite = {
      position: "absolute",
      width: largeurCarres,
      height: largeurCarres,
      top: cadreHauteurPx + "px",
      left: cadreLargeurPx + "px",
      borderStyle: "solid none none none",
      borderWidth: "1px",
      borderColor: "#939191",
      opacity: "0.2",
      transformOrigin: "top left",
      transform: "rotate(225deg)",
    }
    const styleLoader = {
      background: "white",
      zIndex: 2,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      textAlign: "center",
      paddingTop: cadreHauteurPx / 2 - 25,
    }
    const classNamePlus = this.props.classNamePlus

    return (
      <>
        <div
          style={styleCadre}
          className={classNamePlus.concat(" ", "cadreComplet")}
        >
          {this.state.loading || (this.props.doublo !== null && this.state.loading2) ? (
            <div style={styleLoader}>
              <div className="spinner"></div>
            </div>
          ) : (
            ""
          )}

          <div style={styleDivHaut}>
            <ImgBaguette
              id={this.props.idProduct}
              onLoad={() => this.setState({loading:false})}
              style={styleImageHaut}
              alt={"Baguette1"}
            />
          </div>
          <div style={styleDivBas}>
            <ImgBaguette
              id={this.props.idProduct}
              style={styleImageBas}
              alt={"Baguette1"}
            />
          </div>
          <div style={styleDivDroite}>
            <ImgBaguette
              id={this.props.idProduct}
              style={styleImageDroite}
              alt={"Baguette1"}
            />
          </div>
          <div style={styleDivGauche}>
            <ImgBaguette
              id={this.props.idProduct}
              style={styleImageGauche}
              alt={"Baguette1"}
            />
          </div>
          {this.props.doublo !== null ? (
            <>
              <div style={styleDivHaut2}>
                <ImgBaguette
                  id={this.props.doublo.ba_id}
                  style={styleImageHaut2}
                  onLoad={() => this.setState({ loading2: false })}
                  alt={"Baguette1"}
                />
              </div>
              <div style={styleDivBas2}>
                <ImgBaguette
                  id={this.props.doublo.ba_id}
                  style={styleImageBas2}
                  alt={"Baguette1"}
                />
              </div>
              <div style={styleDivDroite2}>
                <ImgBaguette
                  id={this.props.doublo.ba_id}
                  style={styleImageDroite2}
                  alt={"Baguette1"}
                />
              </div>
              <div style={styleDivGauche2}>
                <ImgBaguette
                  id={this.props.doublo.ba_id}
                  style={styleImageGauche2}
                  alt={"Baguette1"}
                />
              </div>
            </>
          ) : null}
          <div
            style={{
              margin: cadreHauteurPx / 2 + "px 0 0 0",
              textAlign: "center",
            }}
          >
            <strong>
              {this.props.service === "plexi_normal"
                ? "+ Plexi normal traité anti UV"
                : this.props.service === "plexi_anti_reflet"
                ? "+ Plexi anti-reflet traité anti UV"
                : this.props.service === "verre_anti_reflet"
                ? "+ Verre anti-reflet"
                : this.props.service === "verre_normal" ||
                  this.props.service === "double_verre_normal"
                ? "+ Verre normal"
                : this.props.service === "verre_invisible" ||
                  this.props.service === "double_verre_invisible"
                ? "+ Verre qualité musée"
                : ""}
            </strong>
          </div>
          <div style={styleCarreHautGauche}></div>
          <div style={styleCarreHautDroite}></div>
          <div style={styleCarreBasDroite}></div>
          <div style={styleCarreBasGauche}></div>
        </div>
      </>
    )
  }
}
PrevisuCadreComplet.defaultProps = {
  doublo:null
}
PrevisuCadreComplet.propTypes = {
  image: PropTypes.object,
  idProduct: PropTypes.number,
  styleCadre: PropTypes.object,
  doublo: PropTypes.object,
  hauteur: PropTypes.number,
  tailleMax: PropTypes.number,
  largeurFixe: PropTypes.number,
  hauteurFixe: PropTypes.number,
  largeurCadre: PropTypes.number,
  hauteurCadre: PropTypes.number,
  couleurPassePartout: PropTypes.string,
  classNamePlus: PropTypes.string,
  service: PropTypes.string,
}

export default PrevisuCadreComplet
