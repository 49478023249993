import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { get_service, kit_montage, getPrix } from "../services/prixBaguettes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import { mjsService, setCartonBarriere, setKitMontage } from "../actions/index"
import { useDispatch, useSelector } from "react-redux"

const Calculateur = props => {
  // services selon categorie ou famille
  let services = get_service(props.product)
  const dispatch = useDispatch()
  const pageProduit = useSelector(s => s.PageProduit)
  const identifiants = useSelector(s => s.identifiants).identifiants

  const perimetre = (pageProduit.hauteur + pageProduit.largeur) * 2
  if ((perimetre > props.product.ba_perimetre_maxi && perimetre <= 600) || pageProduit.dimensions_inter === true) {
    services = services.filter(e => e == 2)
    // N'afficher que le précoupé en desosus de 600cm et au dessus de 480cm
  }

  useEffect(() => {
    if (pageProduit.service === null || !services.includes(props.services[pageProduit.service].id)) {
      // si le coupe_assemble_isorel n'existe pas selectionné le premier service
      Object.keys(props.services).forEach(myService => {
        if (services[0] === props.services[myService].id) {
          dispatch(mjsService(myService))
        }
      })
    }
  }, [pageProduit])

  const prix_cb =
    (getPrix(props.product, { ...pageProduit, cartonBarriere: true }, props.produitAnnexe) -
      getPrix(props.product, { ...pageProduit, cartonBarriere: false }, props.produitAnnexe)) *
    (identifiants !== null && identifiants.mode ? 1 + identifiants.margin / 100 : 1)

  const familleAlu = [411, 476, 510, 511, 509, 672, 675, 707]
  const textInfoMuseeHover =
    "Choisir une telle qualité de verre réduit les reflets, respecte les couleurs de votre sujet sans déformation contrairement au verre anti-reflet et protège votre sujet des UV à hauteur de 70% . Ce verre est apprécié par les photographes et les musées mais aussi de clients qui exigent une grande qualité de verre."
  const textInfoMusee =
    "<b>Avantages du verre qualité musée :</b><ul><li>- réduit fortement les reflets</li><li>- respecte les couleurs de votre sujet sans déformation contrairement au verre anti-reflet</li><li>- protège votre sujet des UV à hauteur de 70%</li></ul>Ce verre est apprécié par les photographes et les musées mais aussi de clients qui exigent une grande qualité de verre"
  const textInfoCompletHover = "Cadre livré avec attaches de suspension dans les deux sens serties dans l'isorel"
  const textInfoComplet = "Cadre livré avec attaches de suspension dans les deux sens serties dans l'isorel"
  const ArrayComplet = ["plexi_normal", "plexi_anti_reflet", "verre_normal", "verre_anti_reflet", "coupe_assemble_isorel"]
  return (
    <>
      <table className="table td-vcentered" style={{ marginTop: "1.5rem" }}>
        <thead>
          <tr>
            <th>Service</th>
            <th className={identifiants === null ? "is-hidden" : "has-text-centered "}>Prix unitaire HT</th>
            {/* <th className="has-text-centered">Prix unitaire TTC</th> */}
            <th className="has-text-centered">
              <span className="is-hidden-mobile">Sélection</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(props.services).filter(service => !(service === "precoupe" && props.product.fa_id === 672)).map((service, index) =>
            services.includes(props.services[service].id) ? (
              <tr
                className={pageProduit.service == Object.keys(props.services)[index] ? "serviceSelected" : null}
                key={index}
                style={{ cursor: "pointer", display: service === "precoupe" ? "none" : "table-row" }}
                value={service}
                onClick={() => dispatch(mjsService(service))}
              >
                <td>
                  <span className="columns is-vcentered" style={{ padding: 7 }}>
                    {props.services[service].image || <div style={{ width: 50 }} />}
                    <span>
                      {pageProduit.service === "precoupe" && service === "precoupe" ? (
                        "Pré Coupé"
                      ) : service === "verre_invisible" || service === "double_verre_invisible" ? (
                        <span data-html={true} data-tip={textInfoMusee}>
                          <span title={textInfoMuseeHover} className="not-active">
                            {props.services[service].title}
                            <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: "10px" }} />
                          </span>
                        </span>
                      ) : ArrayComplet.includes(service) ? (
                        <span data-html={true} data-tip={textInfoComplet}>
                          <span title={textInfoCompletHover} className="not-active">
                            {props.services[service].title}
                            <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: "10px" }} />
                          </span>
                        </span>
                      ) : (
                        props.services[service].title
                      )}
                      {pageProduit.doublo !== null ? " doublo" : null}
                      {service === "precoupe" &&
                      [510, 511].includes(props.product.fa_id) && ( //ALU 2100 && 350
                          <span data-tip={"Livré avec son sachet d’accessoires pour un montage simple et rapide du cadre"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </span>
                        )}
                      <ReactTooltip place="bottom" type="info" effect="solid" event="click" globalEventOff="click" />
                    </span>
                  </span>
                </td>
                <td className={identifiants === null ? "is-hidden" : "has-text-centered "}>
                  {pageProduit.dimensions_valid
                    ? new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(
                        getPrix(
                          props.product,
                          { ...pageProduit, isorel: false, ...props.services[service].pageProduit, rehausse: null, cartonBarriere: false },
                          props.produitAnnexe
                        ) * (identifiants !== null && identifiants.mode ? 1 + identifiants.margin / 100 : 1)
                      )
                    : "-"}
                </td>
                <td className="has-text-centered">
                  <input type="radio" name="selectService" value={service} onChange={() => {}} checked={pageProduit.service === service ? true : false} />
                </td>
              </tr>
            ) : (
              <tr key={index}></tr>
            )
          )}
        </tbody>
      </table>

      {pageProduit.service !== null && props.services[pageProduit.service].option_carton_barriere && identifiants !== null ? (
        <strong style={{ fontSize: "1.3rem" }}>
          {" "}
          <input
            type="checkbox"
            id="carton_barriere"
            onChange={() => {}}
            onClick={() => dispatch(setCartonBarriere(!pageProduit.cartonBarriere))}
            checked={pageProduit.cartonBarriere}
          />
          <label htmlFor="carton" style={{ cursor: "pointer" }}>
            {" "}
            Ajouter un carton barrière à {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(prix_cb)} HT
          </label>
        </strong>
      ) : (
        ""
      )}

      {pageProduit.service === "precoupe" && !familleAlu.includes(props.product.fa_id) && props.product.ba_id !== 1928 ? (
        <>
          <strong style={{ fontSize: "1.3rem" }}>
            <input type="checkbox" id="kit_montage" checked={pageProduit.kitMontage} onClick={() => dispatch(setKitMontage(!pageProduit.kitMontage))} />

            <label htmlFor="kit_montage" style={{ cursor: "pointer" }}>
              {" "}
              Ajouter un kit montage par cheville à{" "}
              {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(
                kit_montage * (identifiants !== null && identifiants.mode ? 1 + identifiants.margin / 100 : 1)
              )}{" "}
              HT
            </label>
          </strong>
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/1DaSM3ll-so"
            frameBorder="0"
            title="vidéo kit montage"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </>
      ) : (
        ""
      )}
    </>
  )
}

Calculateur.propTypes = {
  product: PropTypes.object,
  services: PropTypes.object,
  produitAnnexe: PropTypes.array,
}

export default Calculateur
